<script setup lang="ts">
import type { Promotion } from "@/types";

type cardData = {
	type: Promotion["type"];
	data: {
		available: boolean;
		birthdayGiftTitle: string;
		birthdayGiftEndDate: string;
		playerSectionId: number;
		birthdayGiftEntries: number;
	};
};

const props = defineProps<{
	data: cardData;
	title: string;
}>();

const { t } = useT();
const { refresh: refreshPromo } = useHomePage();
const { colorSchema } = useStatusColor({ statusId: props.data?.data?.playerSectionId || 1 });
const borderGradient = `linear-gradient(180deg, ${colorSchema?.color} 0%, ${colorSchema?.colorSecondary}73.61%)`;

const { execute: applyBonus } = useAsyncFetch({
	path: "/rest/rank-league/birthday-gift/apply/",
	method: "post",
	options: {
		immediate: false
	},
	fetchOptions: {
		onResponse: () => {
			refreshPromo();
		}
	}
});

const handleClick = () => {
	applyBonus();
};
</script>

<template>
	<MPromotionWrapper :type="data.type" :title="title" :buttonName="t('Claim')" @click-card="handleClick">
		<template #top>
			<NuxtImg
				class="gift"
				:src="`/nuxt-img/vip/birthday/gift-${data?.data?.playerSectionId}.png`"
				:alt="data.data.birthdayGiftTitle"
				format="avif"
				width="664"
				height="312"
				loading="lazy"
			/>
		</template>
		<div>
			<AText :size="14" :modifiers="['medium', 'center']" as="div"> {{ t("Your gift is") }} </AText>
			<MPrizeFund id="claim-bonus-birthday" icon="20/entries" variant="entries" :offset="0.4" :iconSize="32">
				<AText :size="40" class="text-natural-100" :modifiers="['bold']">
					{{ numberFormat(data.data?.birthdayGiftEntries) }}
				</AText>
			</MPrizeFund>
		</div>
		<MCounterBadgeGroup
			v-if="data.data.birthdayGiftEndDate"
			:timestamp="data.data.birthdayGiftEndDate ?? ''"
			:badgeText="t('Expires in')"
		/>
		<AText class="text" :size="14" :modifiers="['bold', 'center']" as="div">
			<i18n-t keypath="Enjoy your {key} gift!">
				<template #key>
					{{ data.data.birthdayGiftTitle }}
				</template>
			</i18n-t>
		</AText>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.card-rankLeagueBirthdayGift {
	@include gradient-border(v-bind(borderGradient), 2px, "", v-bind("colorSchema?.backgroundGradient"));
	.gift {
		width: 100%;
		height: auto;
	}
	.box-counter {
		margin: 16px 0 8px;
		text-align: center;
	}
}
</style>
